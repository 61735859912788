import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { addToCart, removeFromCart, updateQuantity, bulkRemoveFromCart } from '../redux/reducer/cartSlice';
import { productCart } from '../api/apiProduct';

const useCart = () => {
    const dispatch = useDispatch();
    const cart = useSelector((state) => state.cart);

    const toggleCart = (productId, quantity = null, selectedSpecificationId = []) => {
      
        let data = { product_id: productId }
        const isCart = cart.some(item => item.id === productId);
        if(Array.isArray(productId)){
        
        }
        else{
        productId = parseInt(productId);
        
       
      
        if (quantity) {
            data.quantity = quantity;
        }
        const specifications = selectedSpecificationId.map((spec) => ({
            specification_id: spec.specificationId,
            item_id: spec.itemId
        }));

        if(specifications.length > 0){
            data.specifications = specifications;
        }
    }
     
        productCart(data).then((res) => {
            if (res.data.status == "success") {
                if (Array.isArray(productId)) {
                    productId.forEach((item) => {
                      dispatch(removeFromCart({ id: item }));
                    });
                  
               }
                else {
                    if (isCart) {
                        if (quantity) {
                            dispatch(updateQuantity({ id: productId, quantity: quantity }));
                        }
                        else {
                            dispatch(removeFromCart({ id: productId, quantity: quantity }));
                        }
                    }
                    else {
                        dispatch(addToCart({ id: productId, quantity: quantity }));
                    }
                }

            }
        }).catch((err) => {

        })

    }
    return toggleCart;
}

export default useCart